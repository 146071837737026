import PropTypes from 'prop-types';
import {useState} from 'react';

/**
 * Instance warning strip
 * @param color {string}
 * @param hidden {boolean}
 * @param text {string}
 * @returns {JSX.Element}
 * @constructor
 */
const InstanceWarning = ({color, hidden, text}) => {
	const [closed, setClosed] = useState(hidden);

	return closed ? null : (
		<div
			style={{
				top: 0,
				display: 'flex',
				position: 'absolute',
				left: 0,
				color: 'white',
				background: color || '#d81b1b',
				width: '100%',
				zIndex: 9999,
			}}
		>
			<div
				style={{
					position: 'relative	',
					margin: '0 auto',
				}}
			>
				{text || 'DEV version'}
			</div>
			<div
				onClick={() => setClosed(true)}
				style={{
					position: 'absolute',
					right: 10,
					cursor: 'pointer',
				}}
			>
				&#10006;
			</div>
		</div>
	);
};

InstanceWarning.propTypes = {
	color: PropTypes.string,
	hidden: PropTypes.bool,
	text: PropTypes.string,
};

export default InstanceWarning;
