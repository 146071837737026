import PropTypes from 'prop-types';
import {ResponsiveBar} from '@nivo/bar';
import './style.scss';

/**
 * @param ChartComponent {React.FunctionComponent}
 * @param chartComponentSettings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param data {Array} following nivo line chart input data format (https://nivo.rocks/line/)
 * @param metadata {Object}
 * @param metadata.settings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param [Tooltip] {React.FunctionComponent}
 * @param startDate {string} ISO 8601 date
 * @param endDate {string} ISO 8601 date
 * @param onHover {function} Callback on hover chart node.
 * @param displayTimeFormat {string} format od displayed time range
 * @returns {JSX.Element|null}
 */
// eslint-disable-next-line react/display-name
const BarChart = ({data, Tooltip, metadata}) => {
	const {settings} = metadata; // TODO if no settings, then basic settings?
	return (
		<div className="ptr-BarChart">
			<ResponsiveBar data={data} tooltip={Tooltip} {...settings} />
		</div>
	);
};

BarChart.propTypes = {
	data: PropTypes.array,
	Tooltip: PropTypes.func,
	metadata: PropTypes.object,
};

export default BarChart;
