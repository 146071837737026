import PropTypes from 'prop-types';
import './style.scss';

const ComparativeChartLegendItem = ({id, color, symbol}) => {
	return (
		<div className="unhab-ComparativeChartLegendItem">
			<div
				className="unhab-ComparativeChartLegendItem-color"
				style={{
					backgroundColor: color,
					borderRadius: symbol === 'circle' ? '.5rem' : '0rem',
				}}
			/>
			<div className="unhab-ComparativeChartLegendItem-title">{id}</div>
		</div>
	);
};

ComparativeChartLegendItem.propTypes = {
	id: PropTypes.string,
	color: PropTypes.string,
	name: PropTypes.string,
	symbol: PropTypes.string,
};

const ComparativeChartLegend = ({data}) => {
	return data?.length ? (
		<div className="unhab-ComparativeChartLegend">
			{data.map((items, index) => (
				<div className="unhab-ComparativeChartLegend-section" key={index}>
					{items.map(item => (
						<ComparativeChartLegendItem
							key={item.id}
							id={item.id}
							color={item.color}
							symbol={item.symbol}
						/>
					))}
				</div>
			))}
		</div>
	) : null;
};

ComparativeChartLegend.propTypes = {
	data: PropTypes.array,
	showFeatureKey: PropTypes.bool,
};

export default ComparativeChartLegend;
