import './style.scss';
import PropTypes from 'prop-types';
import LineChart from '../../../LineChart';
import {useEffect} from 'react';
import ChartWrapper from '../../ChartWrapper';
import BarChart from '../../../BarChart';
import ComparativeChartLegend from './Legend';
import utils from '../../../../../utils';

const tooltip = ({indexValue, color, id, value, data}) => {
	const indexId = id[0];
	const region = data[indexId];
	const fullName = id.replace(indexId, `${region} - `);
	const wholeNumber = Math.floor(value);
	const decimal = utils.getDigitsAfterDecimal(value, 3);
	return (
		<div className="unhab-ChartTooltip">
			<div className="unhab-ChartTooltip-content">
				<div className="unhab-ChartTooltip-title">
					<span
						className="unhab-ChartTooltip-color"
						style={{
							background: color,
						}}
					/>
					<span className="unhab-ChartTooltip-name">{fullName}</span>
				</div>
				<div className="unhab-ChartTooltip-attribute">
					<div className="unhab-ChartTooltip-attributeName">{indexValue}:</div>
					<div className="unhab-ChartTooltip-attributeValue">
						{wholeNumber.toLocaleString('en-US')}
						{decimal ? <span>{`.${decimal}`}</span> : null}
					</div>
				</div>
			</div>
		</div>
	);
};

const ComparativeChart = ({
	data,
	title,
	subtitle,
	configuration,
	report,
	selectable,
	selected,
	onSelectedChange,
	onMount,
	onUnmount,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}
		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	return data && (!report || (report && selected)) ? (
		<ChartWrapper
			report={report}
			title={title}
			subtitle={subtitle}
			data={data}
			selectable={selectable}
			selected={selected}
			onSelectedChange={onSelectedChange}
		>
			<div className="unhab-ComparativeChart-legend">
				<ComparativeChartLegend data={data.legend} showFeatureKey />
			</div>
			<div className="unhab-ComparativeChart">
				<BarChart
					ChartComponent={LineChart}
					data={Object.values(data.chart)}
					metadata={configuration.metadata}
					Tooltip={tooltip}
				/>
			</div>
		</ChartWrapper>
	) : null;
};

ComparativeChart.propTypes = {
	data: PropTypes.array,
	configuration: PropTypes.object,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	report: PropTypes.bool,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	selectable: PropTypes.bool,
	selected: PropTypes.bool,
	onSelectedChange: PropTypes.func,
};

export default ComparativeChart;
