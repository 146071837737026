import theme from './_theme';

export default {
	componentKey: 'landConsumptionRate_populationGrowthRate_compare_chart',
	type: 'comparativeChart',
	title: 'Land consumption rate & Population growth rate',
	subtitle: '5-year period values',
	configuration: {
		attributes: [
			'61d2b450-9546-4e68-8bf6-d09603dbaea2',
			'de759d8f-bb00-441a-a06f-0a060c855637',
			'c09bac02-f276-4ea4-a5f6-e090a8313454',
			'fedcb00a-92dc-4a03-acf0-a63040373b9f',
			'ca15b319-d332-49ca-870c-b9240bd340a2',
			'73e1d399-0f65-46ea-b8d4-fc91b715cd21',
		], 
		templates: {
			ghsBuiltUpSurface: ['c09bac02-f276-4ea4-a5f6-e090a8313454', 'fedcb00a-92dc-4a03-acf0-a63040373b9f'],
			ghsPopulationGrid: ['c09bac02-f276-4ea4-a5f6-e090a8313454', 'fedcb00a-92dc-4a03-acf0-a63040373b9f'],
			wsfBuildUp2016_2023: ['61d2b450-9546-4e68-8bf6-d09603dbaea2', 'de759d8f-bb00-441a-a06f-0a060c855637'],
			wsfPopulation2016_2023: ['61d2b450-9546-4e68-8bf6-d09603dbaea2', 'de759d8f-bb00-441a-a06f-0a060c855637'],
			wsfEvolution1985_2023: ['ca15b319-d332-49ca-870c-b9240bd340a2'],
			wsfEvolution1985_2015: ['73e1d399-0f65-46ea-b8d4-fc91b715cd21'],
		},
		metadata: {
			extendLines: true,
			settings: {
				keys: ["0LCR", "0PGR", "1LCR", "1PGR"],
				indexBy: "period",
				groupMode: "grouped",
				enableLabel: false,
				hideLegend: false,
				theme,
				colorBy: "id",
				colors: serie => serie.data[serie.id + "Color"],
				areaOpacity: 0.6,
				margin: {top: 5, right: 15, bottom: 35, left: 50},
				innerPadding: 1,
				padding: 0.15,
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: {
					tickValues: 4,
				},
				axisBottom: {
					type: 'time',
					format: (value) => {
						return (`${value.slice(2, 4)}/${value.slice(-2)}`)
				}},
				enableGridX: false,
				enableGridY: true,
				gridYValues: 4,
				gridXValues: 6,
				pointSize: 0,
				useMesh: true,
				animate: false,
				motionConfig: 'default',
				legends: [],
				enableSlices: 'x',
			},
		},
	},
};
