import PropTypes from 'prop-types';
import classnames from 'classnames';
import DownloadChart from './DownloadChart';
import {useRef, useState} from 'react';
import Box, {BoxSubtitle, BoxTitle} from '../Box';
import './style.scss';
import {IconTool, Tooltip} from '@gisatcz/ptr-atoms';

const ChartWrapper = ({
	title,
	subtitle,
	children,
	data,
	report,
	selected,
	selectable,
	onSelectedChange,
}) => {
	const [collapsed, setCollapsed] = useState(false);
	const chartRef = useRef();
	const classes = classnames('unhab-Chart', {
		'is-report': report,
		'is-collapsed': collapsed,
	});
	const collapsControlClasses = classnames('unhab-Chart-collapsedControl', {
		'is-collapsed': collapsed,
	});
	return (
		<Box className={classes} ref={chartRef}>
			<div className="unhab-Chart-header">
				<div className="unhab-Chart-title">
					{selectable ? (
						<input
							checked={selected}
							type="checkbox"
							className="unhab-IndicatorBoxHeader-checkbox"
							onChange={() => onSelectedChange(!selected)}
						/>
					) : null}
					<div className="unhab-Chart-names">
						<BoxTitle
							onClick={selectable ? () => onSelectedChange(!selected) : null}
						>
							{title}
						</BoxTitle>
						{subtitle ? <BoxSubtitle>{subtitle}</BoxSubtitle> : null}
					</div>
				</div>
				{!report ? (
					<div className="unhab-Chart-tools">
						<DownloadChart chartRef={chartRef} data={data} label={title} />
						{collapsed ? (
							<IconTool
								className={collapsControlClasses}
								icon="ri-chevron-up"
								onClick={() => setCollapsed(!collapsed)}
								small
								tooltip={{
									text: 'Expand',
									position: 'top',
									component: Tooltip,
								}}
							/>
						) : (
							<IconTool
								className={collapsControlClasses}
								icon="ri-chevron-down"
								onClick={() => setCollapsed(!collapsed)}
								small
								tooltip={{
									text: 'Collapse',
									position: 'top',
									component: Tooltip,
								}}
							/>
						)}
					</div>
				) : null}
			</div>
			<div className="unhab-Chart-body">{children}</div>
		</Box>
	);
};

ChartWrapper.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	children: PropTypes.node,
	data: PropTypes.array,
	report: PropTypes.bool,
	selectable: PropTypes.bool,
	selected: PropTypes.bool,
	onSelectedChange: PropTypes.func,
};

export default ChartWrapper;
