import {createSelector} from 'reselect';
import createCachedSelector from 're-reselect';
import {uniqBy as _uniqBy} from 'lodash';
import {Select} from '@gisatcz/ptr-state';
import indicatorsSelectors from '../indicators/selectors';
import areaTreeTypesSelectors from '../areaTreeTypes/selectors';
import cityClusterMethodsSelectors from '../cityClusterMethods/selectors';
import benchmarkComparisonTypesSelectors from '../benchmarkComparisonTypes/selectors';
import featuresForScatterPlot from '../featuresForScatterPlot/selectors';
import datasetSelectors from '../datasets/selectors';
import utils from '../../../utils';
import featureUtils from '../../../utils/features';
import selectorHelpers from '../selectorHelpers';
import {
	benchmarkViewKey,
	distinctColours,
	reportViewKey,
	cityClustersAreaTreeKey,
	datasetsComparisonType,
	auSelectionKey,
	benchmarkArea1SelectionKey,
	benchmarkArea2SelectionKey,
	areaComparisonType,
	defaultPeriod,
	reportingPeriod,
} from '../../../constants/app';

/**
 * For now, just get normalization attribute columnName
 * @param configuration {Object} Chart configuration
 */
const getNormalizationDefinition = createCachedSelector(
	[
		Select.attributes.getAllAsObject,
		(state, configuration) => configuration?.normalization,
	],
	(attributes, normalization) => {
		if (normalization && attributes?.[normalization.attribute]) {
			const attribute = attributes?.[normalization.attribute];
			return {
				...normalization,
				attribute: attribute?.data?.configuration?.columnName,
			};
		} else {
			return null;
		}
	},
)((state, config, key) => key);

// Charts selectors
const getComparativeData = createSelector(
	[
		indicatorsSelectors.getActiveFeaturesByActiveAreaTreeKey,
		Select.selections.getActive,
		selectorHelpers.getFilteredAttributesByDatasets,
		Select.views.getActiveKey,
		areaTreeTypesSelectors.getActiveKey,
		benchmarkComparisonTypesSelectors.getActiveKey,
		datasetSelectors.getActiveModels,
		(state, configuration) => configuration?.templates,
		cityClusterMethodsSelectors.getActiveModels,
	],
	(
		features,
		activeSelection,
		attributes,
		viewKey,
		activeAreaTreeKey,
		benchmarkComparisonType,
		activeDatasets,
		templates,
		cityClusterMethods,
	) => {
		// only for benchmark and report
		if (
			(viewKey === benchmarkViewKey || viewKey === reportViewKey) &&
			attributes &&
			features
		) {
			const uniqueFeatures = _uniqBy(features, 'key');

			let chartData = {};
			let legendData = [];

			if (benchmarkComparisonType === areaComparisonType) {
				// Areas comparison
				let attributesByActiveDataset = attributes;
				if (activeDatasets?.length === 1) {
					attributesByActiveDataset = attributes.filter(attribute => {
						return templates[activeDatasets[0].key].find(
							template => template === attribute.attributeKey,
						);
					});
				}

				if (attributesByActiveDataset.length > 1) {
					uniqueFeatures.forEach((feature, featureIndex) => {
						const {data: properties} = feature;

						let color;
						if (viewKey === benchmarkViewKey || viewKey === reportViewKey) {
							color = distinctColours[featureIndex];
						} else {
							color = utils.getSelectedFeaturePrimaryColor(
								feature?.key,
								activeSelection?.data,
							);
						}

						const attributeData = [
							{
								id:
									properties.ADM1_NAME ||
									properties.ADM0_NAME ||
									properties.name,
								color: color,
								symbol: 'circle',
							},
						];

						attributesByActiveDataset.forEach((attribute, attributeIndex) => {
							const filteredDataAsObject =
								selectorHelpers.filterPropertiesByTemplate(
									properties,
									attribute.sourceColumnNameTemplate,
								);

							const aggregatedData = selectorHelpers.getAverageValuesForPeriod(
								filteredDataAsObject,
								Object.keys(filteredDataAsObject).map(year => {
									return parseInt(year);
								}),
								defaultPeriod,
								reportingPeriod,
							);

							let rgb_color;
							var hex_color = color.replace('#', ''),
								r = parseInt(hex_color.substring(0, 2), 16),
								g = parseInt(hex_color.substring(2, 4), 16),
								b = parseInt(hex_color.substring(4, 6), 16);

							if (attributeIndex === 0) {
								rgb_color = 'rgb(' + r + ',' + g + ',' + b + ')';
							} else {
								rgb_color = 'rgba(' + r + ',' + g + ',' + b + ',0.5)';
							}

							const attributeName = attribute.shortName;
							const tooltipName = properties.ADM1_NAME
								? `${properties.ADM1_NAME} (${properties.ADM0_NAME})`
								: `${properties.ADM0_NAME || properties.name}`;

							aggregatedData.forEach(item => {
								chartData = {
									...chartData,
									[item.period]: {
										...chartData[item.period],
										period: item.period,
										[featureIndex + attributeName]: item.value,
										[featureIndex + attributeName + 'Color']: rgb_color,
										[featureIndex]: tooltipName,
									},
								};
							});
							attributeData.push({
								id: attributeName,
								color: rgb_color,
								symbol: 'square',
							});
						});
						legendData.push(attributeData);
					});
					return {chart: chartData, legend: legendData};
				} else {
					return null;
				}
			} else if (
				cityClusterMethods?.length > 0 &&
				activeAreaTreeKey === cityClustersAreaTreeKey &&
				benchmarkComparisonType === datasetsComparisonType
			) {
				// City clusters
				let attributeData = {};
				const uniqueClusters = [];
				cityClusterMethods.forEach(method => {
					uniqueClusters.includes(method) ? null : uniqueClusters.push(method);
				});

				uniqueClusters.forEach((clusterMethod, clusterMethodIndex) => {
					const relatedAttributes = attributes.filter(attribute => {
						return attribute.originMethod.key === clusterMethod.key;
					});

					let trueIndex = 0;
					relatedAttributes.forEach(attribute => {
						const method = attribute.originMethod;
						const methodData = attribute.originMethod.data;
						const color = methodData.color;

						attributeData = {
							...attributeData,
							[attribute.originMethod.key]: {
								...attributeData[attribute.originMethod.key],
								[attribute.originMethod.key]: {
									id: methodData.nameDisplay,
									color: color,
									symbol: 'circle',
								},
							},
						};

						const paramsRelatedToAttribute = method.parametersKeys;

						const feature = features.find(
							f => f.key === paramsRelatedToAttribute,
						);

						const featureProperties = feature?.data;
						if (featureProperties) {
							const filteredDataAsObject =
								selectorHelpers.filterPropertiesByTemplate(
									featureProperties,
									attribute.sourceColumnNameTemplate,
								);

							if (Object.keys(filteredDataAsObject).length !== 0) {
								const aggregatedData =
									selectorHelpers.getAverageValuesForPeriod(
										filteredDataAsObject,
										Object.keys(filteredDataAsObject).map(year => {
											return parseInt(year);
										}),
										defaultPeriod,
										reportingPeriod,
									);

								let rgb_color;
								var hex_color = color.replace('#', ''),
									r = parseInt(hex_color.substring(0, 2), 16),
									g = parseInt(hex_color.substring(2, 4), 16),
									b = parseInt(hex_color.substring(4, 6), 16);

								if (
									trueIndex === 0 ||
									(uniqueClusters.length === 1 && trueIndex === 1)
								) {
									rgb_color = 'rgb(' + r + ',' + g + ',' + b + ')';
								} else {
									rgb_color = 'rgba(' + r + ',' + g + ',' + b + ',0.5)';
								}

								trueIndex = trueIndex + 1;

								const attributeName = attribute.shortName;

								aggregatedData.forEach(item => {
									const year = Number(item.period.slice(0, 4));
									chartData = {
										...chartData,
										[year]: {
											...chartData[year],
											period: item.period,
											[clusterMethodIndex + attributeName]: item.value,
											[clusterMethodIndex + attributeName + 'Color']: rgb_color,
											[clusterMethodIndex]: methodData.shortName,
										},
									};
								});
								attributeData = {
									...attributeData,
									[attribute.originMethod.key]: {
										...attributeData[attribute.originMethod.key],
										[attribute.template]: {
											id: attributeName,
											color: rgb_color,
											symbol: 'square',
										},
									},
								};
							} else {
								return null;
							}
						} else {
							return null;
						}
					});
				});
				return {
					chart: chartData,
					legend: Object.values(attributeData).map(value =>
						Object.values(value),
					),
				};
			} else if (benchmarkComparisonType === datasetsComparisonType) {
				// Datasets comparison
				const feature = uniqueFeatures[0];

				const canBeCompared = activeDatasets
					.map(dataset => {
						return (
							attributes.filter(attribute => {
								return templates[dataset.key].find(
									template => template === attribute.attributeKey,
								);
							}).length > 1
						);
					})
					.includes(true);

				if (canBeCompared) {
					activeDatasets.forEach((activeDataset, datasetIndex) => {
						const {data: properties} = activeDataset;
						const color = properties.color;

						const attributeData = [
							{
								id: properties.shortName || properties.name,
								color: color,
								symbol: 'circle',
							},
						];

						const attributesByActiveDataset = attributes.filter(attribute => {
							return templates[activeDataset.key].find(
								template => template === attribute.attributeKey,
							);
						});

						attributesByActiveDataset.forEach((attribute, attributeIndex) => {
							const filteredDataAsObject =
								selectorHelpers.filterPropertiesByTemplate(
									feature?.data,
									attribute.sourceColumnNameTemplate,
								);

							const aggregatedData = selectorHelpers.getAverageValuesForPeriod(
								filteredDataAsObject,
								Object.keys(filteredDataAsObject).map(year => {
									return parseInt(year);
								}),
								defaultPeriod,
								reportingPeriod,
							);

							let rgb_color;
							var hex_color = color.replace('#', ''),
								r = parseInt(hex_color.substring(0, 2), 16),
								g = parseInt(hex_color.substring(2, 4), 16),
								b = parseInt(hex_color.substring(4, 6), 16);

							if (attributeIndex === 0) {
								rgb_color = 'rgb(' + r + ',' + g + ',' + b + ')';
							} else {
								rgb_color = 'rgba(' + r + ',' + g + ',' + b + ',0.5)';
							}

							const attributeName = attribute.shortName;

							aggregatedData.forEach(item => {
								const year = Number(item.period.slice(0, 4));
								chartData = {
									...chartData,
									[year]: {
										...chartData[year],
										period: item.period,
										[datasetIndex + attributeName]: item.value,
										[datasetIndex + attributeName + 'Color']: rgb_color,
										[datasetIndex]: properties.shortName || properties.name,
									},
								};
							});
							attributeData.push({
								id: attributeName,
								color: rgb_color,
								symbol: 'square',
							});
						});
						legendData.push(attributeData);
					});
					return {
						chart: chartData,
						legend: legendData,
					};
				} else {
					return null;
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	},
);

const getTimeProgressData = createCachedSelector(
	[
		indicatorsSelectors.getActiveFeaturesByActiveAreaTreeKey,
		Select.selections.getActive,
		Select.views.getActiveKey,
		selectorHelpers.getFilteredAttributesByActiveDatasets,
		getNormalizationDefinition,
		areaTreeTypesSelectors.getActiveKey,
		cityClusterMethodsSelectors.getParametersKeysByActiveMethods,
		benchmarkComparisonTypesSelectors.getActiveKey,
	],
	(
		features,
		activeSelection,
		viewKey,
		attributes,
		normalization,
		activeAreaTreeKey,
		activeCityClustersMethodsParametersKeys,
		benchmarkComparisonType,
	) => {
		if (features?.length && attributes?.length) {
			const data = [];

			if (
				features?.length > 1 &&
				activeAreaTreeKey === cityClustersAreaTreeKey &&
				benchmarkComparisonType === datasetsComparisonType
			) {
				attributes.forEach(attribute => {
					const method = attribute.originMethod;

					const paramsRelatedToAttribute =
						attribute.originMethod.parametersKeys;

					const feature = features.find(
						f => f.key === paramsRelatedToAttribute,
					);

					const properties = feature?.data;
					if (properties) {
						const normValue = selectorHelpers.getNormalizationAttributeValue(
							properties,
							normalization,
						);

						const filteredDataAsObject =
							selectorHelpers.filterPropertiesByTemplate(
								properties,
								attribute.template,
							);

						const timeSerie = selectorHelpers.getFormattedTimeSerie(
							filteredDataAsObject,
							normValue,
						);

						if (timeSerie.length > 0) {
							data.push({
								id: paramsRelatedToAttribute,
								name: `${method.data.nameDisplay}`,
								data: timeSerie,
								color: method.data.color,
							});
						}
					}
				});
				return data;
			} else if (features?.length > 1) {
				const uniqueFeatures = _uniqBy(features, 'key');
				uniqueFeatures.forEach((feature, index) => {
					const {data: properties} = feature;
					const normValue = selectorHelpers.getNormalizationAttributeValue(
						properties,
						normalization,
					);
					// xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
					attributes.forEach(attribute => {
						const filteredDataAsObject =
							selectorHelpers.filterPropertiesByTemplate(
								properties,
								attribute.template,
							);
						const timeSerie = selectorHelpers.getFormattedTimeSerie(
							filteredDataAsObject,
							normValue,
						);
						let color;
						if (viewKey === benchmarkViewKey || viewKey === reportViewKey) {
							color = distinctColours[index];
						} else {
							color = utils.getSelectedFeaturePrimaryColor(
								feature?.key,
								activeSelection?.data,
							);
						}

						data.push({
							id: feature?.key,
							name: selectorHelpers.getAreaName(properties),
							data: timeSerie,
							color,
							attribute: attribute?.name,
						});
					});
				});
				return data;
			} else if (attributes?.length === 1) {
				features.forEach(feature => {
					const {data: properties} = feature;
					const attribute = attributes[0];

					const normValue = selectorHelpers.getNormalizationAttributeValue(
						properties,
						normalization,
					);

					const filteredDataAsObject =
						selectorHelpers.filterPropertiesByTemplate(
							properties,
							attribute.template,
						);

					const timeSerie = selectorHelpers.getFormattedTimeSerie(
						filteredDataAsObject,
						normValue,
					);
					data.push({
						id: attribute.template,
						name: attribute.nameCombined || attribute.name,
						data: timeSerie,
						color: attribute.colorCombined || attribute.color,
					});
				});
				return data;
			} else if (features.length === 1) {
				const feature = features[0];
				const {data: properties} = feature;
				const normValue = selectorHelpers.getNormalizationAttributeValue(
					properties,
					normalization,
				);
				attributes.forEach(attribute => {
					const filteredDataAsObject =
						selectorHelpers.filterPropertiesByTemplate(
							properties,
							attribute.template,
						);
					const timeSerie = selectorHelpers.getFormattedTimeSerie(
						filteredDataAsObject,
						normValue,
					);
					data.push({
						id: attribute?.template,
						name: attribute.nameCombined || attribute?.name,
						data: timeSerie,
						color: attribute.colorCombined || attribute?.color,
					});
				});

				return data;
			}

			return null;
		} else {
			// TODO add another ways how to define attributes for time serie
			return null;
		}
	},
)((state, config, key) => key);

const getAreaShareData = createSelector(
	[
		indicatorsSelectors.getActiveFeaturesByActiveAreaTreeKey,
		Select.selections.getActive,
		Select.views.getActiveKey,
		selectorHelpers.getFilteredAttributesByActiveDatasets,
		getNormalizationDefinition,
	],
	(features, activeSelection, viewKey, attributes, normalization) => {
		if (features?.length && attributes?.length) {
			const [themeAttribute] = attributes;
			const areaAttribute = normalization.attribute;
			if (themeAttribute && areaAttribute) {
				const data = [];
				features.forEach((feature, index) => {
					const properties = feature.data;
					const themeValue = properties?.[themeAttribute.key];
					const areaValue = properties?.[areaAttribute];
					let color;
					if (viewKey === benchmarkViewKey || viewKey === reportViewKey) {
						color = distinctColours[index];
					} else {
						color = utils.getSelectedFeaturePrimaryColor(
							feature?.key,
							activeSelection?.data,
						);
					}

					data.push({
						name: selectorHelpers.getAreaName(properties),
						share: themeValue / areaValue,
						data: [
							{
								id: themeAttribute.key,
								label: themeAttribute.name,
								originalValue: themeValue,
								value: themeValue,
								color,
							},
							{
								id: areaAttribute,
								label: areaAttribute,
								originalValue: areaValue - themeValue,
								value: areaValue - themeValue,
								color: '#bbbbbb',
							},
						],
					});
				});
				return data;
			} else {
				return null;
			}
		} else {
			return null;
		}
	},
);

const getMultipleAttributesData = createSelector(
	[
		selectorHelpers.getFilteredAttributesByDatasets,
		featuresForScatterPlot.getAll,
		datasetSelectors.getActiveModels,
		Select.periods.getActive,
		Select.selections.getAllAsObject,
		Select.views.getActiveKey,
		(state, configuration) => configuration,
	],
	(
		attributes,
		features,
		activeDatasets,
		period,
		selections,
		viewKey,
		configuration,
	) => {
		const activeAttributeKeys = filterAttributeKeysByActiveDatasets(
			configuration.attributes,
			activeDatasets,
		);
		if (
			attributes?.length > 1 &&
			features?.length &&
			period &&
			activeAttributeKeys?.length > 0
		) {
			const year = period.data.period.split('/')[1]; // TODO get last year for now
			const finalFeatures = [];
			let atLeastOneFeatureSelected = false;
			features.forEach(feature => {
				const {data: properties, key: featureKey} = feature;
				const data = {};
				const attributeNameByData = {};
				const params = ['x', 'y', 'z'];

				// add values
				attributes.forEach((attribute, i) => {
					const filteredDataAsObject =
						selectorHelpers.filterPropertiesByTemplate(
							properties,
							attribute.template,
						);
					const value = filteredDataAsObject[year];
					if (value) {
						data[params[i]] = value;
					}
					attributeNameByData[params[i]] = attribute.shortAttributeName;
				});

				// add name & color
				data.name = selectorHelpers.getAreaName(properties);
				data.color = selectorHelpers.getFourCountriesAreaColor(properties);
				data.shape = selectorHelpers.getFourCountriesAreaShape(properties);
				data.attributeNameByData = attributeNameByData;

				let fitsFilter = true;
				if (configuration?.regionsOnly) {
					fitsFilter = featureUtils.isFeatureRegion(featureKey);
				} else if (configuration?.countriesOnly) {
					fitsFilter = featureUtils.isFeatureCountry(featureKey);
				}

				// add selection color and check selections
				if (viewKey === benchmarkViewKey || viewKey === reportViewKey) {
					const selectionArea1 = selections[benchmarkArea1SelectionKey];
					if (
						selectionArea1?.data?.featureKeysFilter?.keys?.includes(featureKey)
					) {
						data.selectionColor = utils.getSelectedFeaturePrimaryColor(
							featureKey,
							selectionArea1?.data,
						);
						if (fitsFilter) {
							atLeastOneFeatureSelected = true;
						}
					}
					const selectionArea2 = selections[benchmarkArea2SelectionKey];
					if (
						selectionArea2?.data?.featureKeysFilter?.keys?.includes(featureKey)
					) {
						data.selectionColor = utils.getSelectedFeaturePrimaryColor(
							featureKey,
							selectionArea2?.data,
						);
						if (fitsFilter) {
							atLeastOneFeatureSelected = true;
						}
					}
				} else {
					const activeSelection = selections[auSelectionKey];
					if (
						activeSelection?.data?.featureKeysFilter?.keys?.includes(featureKey)
					) {
						data.selectionColor = utils.getSelectedFeaturePrimaryColor(
							featureKey,
							activeSelection?.data,
						);
						if (fitsFilter) {
							atLeastOneFeatureSelected = true;
						}
					}
				}

				const hasValues =
					data.x ||
					(data.x === 0 && data.y) ||
					(data.y === 0 && data.z) ||
					data.z === 0;

				// add feature
				if (fitsFilter && hasValues) {
					finalFeatures.push({
						id: featureKey,
						data: [data],
					});
				}
			});

			// return data only if at least one area from all features is among selected
			return atLeastOneFeatureSelected ? finalFeatures : null;
		} else {
			return null;
		}
	},
);

function filterAttributeKeysByActiveDatasets(attributeKeys, datasets) {
	if (attributeKeys?.length && datasets?.length) {
		return attributeKeys.filter(attributeKey =>
			datasets.some(dataset =>
				dataset.data.attributes.some(a => a.key === attributeKey),
			),
		);
	} else {
		return null;
	}
}

export default {
	getTimeProgressData,
	getAreaShareData,
	getMultipleAttributesData,
	getComparativeData,
};
